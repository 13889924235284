import classnames from "classnames";
import _ from "lodash";
import React from "react";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import GiftNotificationBadge from "../GiftNotificationBadge";
import CreditCardEmptyIcon from "../icons/CreditCardEmpty.svg";
import GiftIcon from "../icons/Gift.svg";
import IceCreamIcon from "../icons/ice-cream.svg";
import LearnMoreIcon from "../icons/LearnMore.svg";
import NewOrderIcon from "../icons/NewOrder.svg";
import NewOrderPizzaIcon from "../icons/NewOrderPizza.svg";
import PizzaReorderIcon from "../icons/PizzaReorder.svg";
import ReorderIcon from "../icons/Reorder.svg";
import ScanIcon from "../icons/Scan.svg";
import TableIcon from "../icons/Table.svg";
import { LOGIN_TYPES } from "../LoginView";
import * as styles from "./index.module.scss";

export default function HomeButtons({
  T,
  appStyles,
  handleStartNewOrderClicked,
  isMobile,
  amountOfGiftsUnseen,
  disableRewards,
  navigateToTargetOrLoginMobile,
  navigateToTargetOrLoginDesktop,
  showMobilePromotions,
  isSSR,
}) {
  const {
    HomeButtonsDesktop = {},
    HomeButtonsMobile = {},
    roundedHomeButtons,
  } = appStyles;
  const disableReorder = _.includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = _.includes(appStyles.disabledFeatures, "order");
  const tilesMode = isMobile && appStyles.homepageTileButtons;
  const StartNewOrderIcon = appStyles.isPizza
    ? NewOrderPizzaIcon
    : appStyles.isIceCream
    ? IceCreamIcon
    : NewOrderIcon;
  if (showMobilePromotions) {
    return (
      <div>
        <ButtonGroup
          appStyles={appStyles}
          classNames={classnames(
            styles.HideOnDesktop,

            styles.ButtonGroupRow,

            appStyles.rtl && styles.RTL,
          )}
          style={HomeButtonsMobile}
        >
          <Button
            href="#"
            onClick={handleStartNewOrderClicked}
            appStyles={appStyles}
          >
            <span
              style={
                appStyles.noIcons
                  ? { textAlign: "center" }
                  : { textAlign: appStyles.rtl ? "right" : "left" }
              }
            >
              {T("Start New Order")}
            </span>
            <span style={{ flexShrink: 0 }}>
              {!appStyles.noIcons && <StartNewOrderIcon />}
            </span>
          </Button>
        </ButtonGroup>
      </div>
    );
  }
  return (
    <div>
      <ButtonGroup
        appStyles={appStyles}
        round={roundedHomeButtons}
        classNames={classnames(
          styles.HideOnMobile,
          appStyles.homepageTileButtons
            ? styles.ButtonGroupTile
            : styles.ButtonGroupDesktop,
        )}
        style={HomeButtonsDesktop}
      >
        {!disableOrder && (
          <Button
            href="#"
            onClick={handleStartNewOrderClicked}
            appStyles={appStyles}
          >
            <span
              style={
                appStyles.noIcons
                  ? { textAlign: "center" }
                  : { textAlign: appStyles.rtl ? "right" : "left" }
              }
            >
              {T("Start New Order")}
            </span>
            <span style={{ flexShrink: 0 }}>
              {!appStyles.noIcons && <StartNewOrderIcon />}
            </span>
          </Button>
        )}
        {!disableReorder && (
          <Button
            onClick={navigateToTargetOrLoginDesktop({
              type: LOGIN_TYPES.REORDER,
              target: "/reorder",
            })}
            appStyles={appStyles}
          >
            <span>{T("Reorder")}</span>
            {!appStyles.noIcons &&
              (appStyles.isPizza ? <PizzaReorderIcon /> : <ReorderIcon />)}
          </Button>
        )}

        {!disableRewards &&
          (appStyles.rewardsAlternative ? (
            <Button
              isExternalLink
              to={appStyles.rewardsAlternative.href}
              appStyles={appStyles}
            >
              <span>{T(appStyles.rewardsAlternative.label)}</span>
              {!appStyles.noIcons && (
                <TableIcon style={{ width: 37, height: "auto" }} />
              )}
            </Button>
          ) : (
            <Button
              onClick={navigateToTargetOrLoginDesktop({
                type: LOGIN_TYPES.GIFTS,
                target: "/gifts",
              })}
              appStyles={appStyles}
            >
              <span>{T("My Rewards")}</span>
              <div style={{ position: "relative" }}>
                {!appStyles.noIcons && <GiftIcon />}
                <GiftNotificationBadge
                  appStyles={appStyles}
                  amountOfGiftsUnseen={amountOfGiftsUnseen}
                  classNames={classnames(
                    styles.GiftsNotificationDesktop,
                    appStyles.rtl && styles.RTL,
                  )}
                  withBounce
                />
              </div>
            </Button>
          ))}
      </ButtonGroup>
      <ButtonGroup
        appStyles={appStyles}
        tiles={tilesMode}
        classNames={classnames(
          styles.HideOnDesktop,
          !appStyles.homepageImageSplit
            ? styles.ButtonGroupTile
            : styles.ButtonGroupRow,
          !appStyles.homepageTileButtons && styles.ButtonGroupRow,
          appStyles.rtl && styles.RTL,
          roundedHomeButtons && styles.RoundedMobile,
        )}
        style={HomeButtonsMobile}
      >
        {!disableOrder && (
          <Button onClick={handleStartNewOrderClicked} appStyles={appStyles}>
            <span
              style={
                appStyles.noIcons || appStyles.homepageTileButtons
                  ? { textAlign: "center" }
                  : { textAlign: appStyles.rtl ? "right" : "left" }
              }
            >
              {T("Start New Order")}
            </span>
            {!appStyles.noIcons && (
              <span style={{ flexShrink: 0 }}>
                <StartNewOrderIcon />
              </span>
            )}
          </Button>
        )}
        {!disableReorder && (
          <Button
            onClick={navigateToTargetOrLoginMobile({
              type: LOGIN_TYPES.REORDER,
              target: "/reorder",
            })}
            appStyles={appStyles}
          >
            <span>{T("Reorder")}</span>{" "}
            {!appStyles.noIcons &&
              (appStyles.isPizza ? <PizzaReorderIcon /> : <ReorderIcon />)}
          </Button>
        )}
        {disableOrder && disableReorder ? (
          appStyles.isUsingChargeCard ? (
            <Button
              onClick={navigateToTargetOrLoginMobile({
                type: LOGIN_TYPES.PREPAID,
                target: "/prepaid",
              })}
              appStyles={appStyles}
            >
              <span>{T("Recharge Card")}</span>
              {!appStyles.noIcons && (
                <CreditCardEmptyIcon style={{ width: 44, height: 50 }} />
              )}
            </Button>
          ) : (
            <Button
              onClick={navigateToTargetOrLoginMobile({
                type: LOGIN_TYPES.SCAN,
                target: "/scan",
              })}
              appStyles={appStyles}
            >
              <span>{T("Scan")}</span>
              {!appStyles.noIcons && (
                <ScanIcon style={{ width: 44, height: 50 }} />
              )}
            </Button>
          )
        ) : null}

        {!disableRewards &&
          (appStyles.rewardsAlternative ? (
            <Button
              isExternalLink
              to={appStyles.rewardsAlternative.href}
              appStyles={appStyles}
            >
              <span>{T(appStyles.rewardsAlternative.label)}</span>
              {!appStyles.noIcons && (
                <TableIcon style={{ width: 37, height: "auto" }} />
              )}
            </Button>
          ) : (
            <Button
              onClick={navigateToTargetOrLoginMobile({
                type: LOGIN_TYPES.GIFTS,
                target: "/gifts",
              })}
              appStyles={appStyles}
            >
              <span style={tilesMode ? { textAlign: "center" } : {}}>
                {T("My Rewards")}
              </span>
              <div
                style={{
                  position: "relative",
                }}
              >
                {!appStyles.noIcons && <GiftIcon />}
                <GiftNotificationBadge
                  appStyles={appStyles}
                  amountOfGiftsUnseen={amountOfGiftsUnseen}
                  classNames={classnames(
                    isMobile
                      ? styles.GiftsNotificationMobile
                      : styles.GiftsNotificationDesktop,
                    appStyles.rtl && styles.RTL,
                  )}
                  withBounce
                />
              </div>
            </Button>
          ))}
        {isMobile &&
          appStyles.homepageTileButtons &&
          (appStyles.isUsingChargeCard ? (
            <Button
              onClick={navigateToTargetOrLoginMobile({
                type: LOGIN_TYPES.PREPAID,
                target: "/prepaid",
              })}
              appStyles={appStyles}
            >
              <span>{T("Recharge Card")} </span>
              {!appStyles.noIcons && (
                <CreditCardEmptyIcon style={{ width: 37 }} />
              )}
            </Button>
          ) : appStyles.scanAlternative ? (
            <Button
              isExternalLink
              to={appStyles.scanAlternative.href}
              appStyles={appStyles}
            >
              <span style={{ textAlign: "center" }}>
                {T(appStyles.scanAlternative.label)}
              </span>{" "}
              {appStyles.scanAlternative.useLearnMoreIcon ? (
                <LearnMoreIcon style={{ width: "auto", maxHeight: 66 }} />
              ) : (
                <TableIcon style={{ width: 37 }} />
              )}
            </Button>
          ) : (
            <Button
              onClick={navigateToTargetOrLoginMobile({
                type: LOGIN_TYPES.SCAN,
                target: "/scan",
              })}
              appStyles={appStyles}
            >
              <span>{T("Scan")}</span> {!appStyles.noIcons && <ScanIcon />}
            </Button>
          ))}
      </ButtonGroup>
    </div>
  );
}
