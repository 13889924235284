import React, { useEffect } from "react";
import { Avatar, Grow, IconButton, Fab, Slide } from "@mui/material";
import { AvatarGroup } from "@mui/material";
import { map, filter, isEmpty } from "lodash";

import { makeStyles } from "../AppContainer/mui-theme";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import getPromotionImage from "../../utils/get-promotion-image";

export default function PromotionsAvatars({
  onOpen,
  onClose,
  promotions,
  open,
}) {
  const { classes } = useStyles();
  const nonEmptyPromotions = filter(
    promotions,
    (promotion) => getPromotionImage(promotion) || !isEmpty(promotion.title),
  );

  return (
    <>
      <Grow in={open}>
        <Fab
          onClick={onClose}
          color="primary"
          size="small"
          className={classes.closeButton}
        >
          <CloseIcon />
        </Fab>
      </Grow>
      <Grow direction="left" in={!open}>
        <AvatarGroup max={4} onClick={onOpen}>
          {map(nonEmptyPromotions, (promotion, index) => {
            const preview = getPromotionImage(promotion);
            const hasPreview = preview !== "video";
            return (
              <Grow
                key={index}
                in={!open}
                timeout={1000 + index * 500}
                className={classes.avatarWrapper}
              >
                <Avatar alt={promotion.title || ""} src={hasPreview && preview}>
                  <StarIcon color="primary" />
                </Avatar>
              </Grow>
            );
          })}
        </AvatarGroup>
      </Grow>
    </>
  );
}

const avatarSize = 80;

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    alignSelf: "flex-start",
    padding: 2,
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(1),
  },
  avatarWrapper: {
    height: 80,
    width: 80,
    boxShadow: "0 0 10px 2px #555",
    cursor: "pointer",
  },
}));
