// extracted by mini-css-extract-plugin
export var BackgroundImage = "index-module--BackgroundImage--6b564";
export var ButtonGroupDesktop = "index-module--ButtonGroupDesktop--e0baf";
export var ButtonGroupRow = "index-module--ButtonGroupRow--85b62";
export var ButtonGroupTile = "index-module--ButtonGroupTile--367a8";
export var ButtonWithIcon = "index-module--ButtonWithIcon--22394";
export var Content = "index-module--Content--5a55d";
export var GiftsNotificationDesktop = "index-module--GiftsNotificationDesktop--84f89";
export var GiftsNotificationMobile = "index-module--GiftsNotificationMobile--d85ab";
export var HideOnDesktop = "index-module--HideOnDesktop--8741e";
export var HideOnMobile = "index-module--HideOnMobile--e9a21";
export var HomeTitle = "index-module--HomeTitle--a6240";
export var MobileBottomButtons = "index-module--MobileBottomButtons--b0f64";
export var RTL = "index-module--RTL--b966d";
export var RoundedMobile = "index-module--RoundedMobile--ecaf8";
export var ScanAlternative = "index-module--ScanAlternative--221e0";
export var TopSideButtons = "index-module--TopSideButtons--beff1";
export var TopSideMobileButtons = "index-module--TopSideMobileButtons--b5fce";
export var fadeAndGrowIn = "index-module--fadeAndGrowIn--fb01a";