import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, CardMedia, LinearProgress } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import Slider from "react-slick";

import useInterval from "@use-it/interval";
import {
  castArray,
  isEmpty,
  map,
  compact,
  includes,
  filter,
  cloneDeep,
  noop,
  get,
} from "lodash";
import { appfrontCdnRoot, promotionMediaType } from "../../utils/media";
import ReactPlayer from "react-player";
import { useInViewport } from "react-in-viewport";

const progressInterval = 200;
export default function({ promotion, speed = 7000 }) {
  const { classes } = useStyles();
  const sliderRef = useRef(null);
  const videoPlayerRef = useRef(null);

  const { inViewport, enterCount, leaveCount } = useInViewport(sliderRef);
  const [progressCounters, setProgressCounters] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const mediaItems = [
    ...filter(
      compact(
        castArray(
          (promotion.image && {
            ...promotion.image,
            mediaType: promotionMediaType.image,
          }) ||
            promotion.media,
        ),
      ),
      ({ mediaType }) =>
        includes(
          [promotionMediaType.image, promotionMediaType.videoUrl],
          mediaType,
        ),
    ),
  ];

  useEffect(() => {
    if (currentSlide > mediaItems.length - 1) {
      setCurrentSlide(mediaItems.length - 1);
    } else {
      if (currentSlide < 0 && mediaItems.length) {
        setCurrentSlide(0);
      }
    }
  }, [currentSlide, mediaItems.length]);

  const isCurrentSlideVideoUrl =
    get(mediaItems[currentSlide], "mediaType") === promotionMediaType.videoUrl;

  const incrementProgressCounter = () => {
    let updatedProgressCounters = cloneDeep(progressCounters);

    if (isCurrentSlideVideoUrl) {
      if (videoPlayerRef?.current) {
        const duration = videoPlayerRef.current.getDuration();

        const currentTime = videoPlayerRef.current.getCurrentTime();
        console.log({ x: videoPlayerRef.current, currentTime, duration });
        updatedProgressCounters[currentSlide] = Math.min(
          (updatedProgressCounters[currentSlide] || 0) +
            (currentTime * 100) / duration,
          100,
        );
      }
    } else {
      updatedProgressCounters[currentSlide] = Math.min(
        (updatedProgressCounters[currentSlide] || 0) +
          (progressInterval * 100) / speed,
        100,
      );
    }

    if (updatedProgressCounters[currentSlide] === 100) {
      const nextSlide = currentSlide + 1;
      if (nextSlide >= mediaItems.length) {
        updatedProgressCounters = map(updatedProgressCounters, 0);

        setCurrentSlide(0);
      } else {
        setCurrentSlide(nextSlide);
      }
    }
    setProgressCounters(updatedProgressCounters);
  };
  const shouldPause =
    progressCounters[currentSlide] === 100 || isCurrentSlideVideoUrl;

  useEffect(() => {
    if (sliderRef.current) {
      if (sliderRef.current.slickCurrentSlide !== currentSlide) {
        sliderRef.current.slickGoTo(currentSlide);
      }
    }
  }, [currentSlide, sliderRef]);

  useInterval(
    () => {
      if (inViewport) {
        incrementProgressCounter();
      }
    },
    shouldPause ? null : progressInterval,
  );

  const toNextSlide = () => {
    let updatedProgressCounters = cloneDeep(progressCounters);

    updatedProgressCounters[currentSlide] = 100;

    const nextSlide = currentSlide + 1;
    if (nextSlide >= mediaItems.length) {
      updatedProgressCounters = map(updatedProgressCounters, 0);

      setCurrentSlide(0);
    } else {
      setCurrentSlide(nextSlide);
    }

    setProgressCounters(updatedProgressCounters);
  };

  const toPreviousSlide = () => {
    let updatedProgressCounters = cloneDeep(progressCounters);

    updatedProgressCounters[currentSlide] = 0;

    const nextSlide =
      currentSlide - 1 < 0 ? mediaItems.length - 1 : currentSlide - 1;

    updatedProgressCounters = map(updatedProgressCounters, (x, index) =>
      nextSlide > index ? 100 : 0,
    );

    setCurrentSlide(nextSlide);

    setProgressCounters(updatedProgressCounters);
  };

  const handleOnVideoProgress = (index) => (e) => {
    if (currentSlide === index) {
      let updatedProgressCounters = cloneDeep(progressCounters);
      updatedProgressCounters[index] = Math.min(e.played * 100, 100);

      setProgressCounters(updatedProgressCounters);
      if (updatedProgressCounters[index] === 100) {
        toNextSlide();
      }
    }
  };

  const handleNextClicked = (e) => {
    toNextSlide();
  };

  const handlePrevClicked = (e) => {
    console.log(
      "handlePrevClicked",
      { isCurrentSlideVideoUrl },
      videoPlayerRef.current,
    );
    if (
      // seek to the begining of the movie if past few seconds
      isCurrentSlideVideoUrl &&
      progressCounters[currentSlide] > 5 &&
      videoPlayerRef.current
    ) {
      videoPlayerRef.current.seekTo(0);
    } else {
      toPreviousSlide();
    }
  };

  const handleOnVideoEnded = (index) => (e) => {
    console.log("ended");
    if (currentSlide === index) {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.seekTo(0.01);
        if (mediaItems.length === 1) {
          console.log("tryies to replay");
          videoPlayerRef.current.player.handlePlay();
        }
      }
      toNextSlide();
    }
  };

  if (isEmpty(mediaItems)) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{ position: "relative" }}
    >
      <Grid item>
        <SlideControls
          onNextCLicked={handleNextClicked}
          onPrevClicked={handlePrevClicked}
          disableNext={mediaItems.length === 1}
        />
        <Slider ref={sliderRef} {...settings} className={classes.slider}>
          {map(mediaItems, (mediaItem, index) =>
            mediaItem.mediaType === promotionMediaType.videoUrl ? (
              <VideoUrlPlayer
                loop={mediaItems.length === 1}
                key={mediaItem.key + `${index}`}
                url={mediaItem.url}
                onProgress={handleOnVideoProgress(index)}
                onEnded={handleOnVideoEnded(index)}
                playing={currentSlide === index && inViewport}
                ref={currentSlide === index ? videoPlayerRef : undefined}
              />
            ) : (
              <CardMedia
                style={{ pointerEvents: "none" }}
                key={`${index}` + mediaItem.key}
                image={`${appfrontCdnRoot}/${mediaItem.key}`}
                component="img"
              />
            ),
          )}
        </Slider>
      </Grid>

      {(mediaItems.length > 1 || isCurrentSlideVideoUrl) && (
        <Grid
          style={{ padding: "0 16px" }}
          item
          container
          direction="row"
          spacing={1}
          justifyContent="space-evenly"
        >
          {map(mediaItems, (mediaItem, index) => (
            <Grid key={mediaItem.key + `${index}`} item style={{ flexGrow: 1 }}>
              <LinearProgress
                value={progressCounters[index] || 0}
                variant="determinate"
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

const VideoUrlPlayer = React.forwardRef(
  ({ url, onProgress, onEnded, playing, loop }, ref) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "#000",
          height: "100%",
        }}
      >
        <ReactPlayer
          ref={ref}
          playing={playing}
          onProgress={onProgress}
          muted
          controls={false}
          onEnded={onEnded}
          // playsinline
          loop={loop}
          // onReady={(e) => e.target.mute()}
          config={{
            youtube: {
              playerVars: {
                showInfo: 1,
                disablekb: 1,
                modestbranding: 1,
                playsinline: 1,
                loop: 1,
                iv_load_policy: 3,
                cc_load_policy: 1,
                fs: 0,
              },
            },
          }}
          url={url}
          width="100%"
          style={{ background: "#000", zIndex: 1 }}
        />
      </div>
    );
  },
);

const SlideControls = ({
  onNextCLicked = noop,
  onPrevClicked = noop,
  disableNext,
}) => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.sliderNoClickArea} />
      <div
        className={classes.sliderNoClickArea}
        style={{ bottom: 0, top: "unset", height: 146 }}
      />
      <Button
        disableRipple
        onClick={onPrevClicked}
        className={classes.sliderSkipButton}
        style={{ left: 0 }}
      />
      <Button
        disableRipple
        onClick={disableNext ? onPrevClicked : onNextCLicked}
        className={classes.sliderSkipButton}
        style={{ right: 0 }}
      />
    </>
  );
};

const desktopCardPromotionWidth = 376;

const useStyles = makeStyles()((theme) => ({
  sliderNoClickArea: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 120,
    zIndex: 2,
    width: "100%",
  },
  sliderSkipButton: {
    "&:hover": {
      background: "rgba(0,0,0,0.0)",
    },
    position: "absolute",
    top: 0,
    height: "100%",
    width: 150,
    zIndex: 2,
  },
  slider: {
    height: `calc(100vw - ${theme.spacing(4)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      height: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
      width: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
    },
  },
}));

const settings = {
  lazyLoad: "ondemand",
  arrows: false,
  dots: false,
  infinite: true,
  touchMove: false,
  swipeToSlide: false,
  swipe: false,
  touchMove: false,
  zIndex: 1,
  speed: 0,
  fade: 1,
  slidesToShow: 1,
  slidesToScroll: 1,
};
